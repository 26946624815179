<template>
  <div style="background-color: #f6f6f6; height: 100%;" v-if="tableData">
    <van-nav-bar
      title="试玩详情"
      left-arrow
      @click-left="goBack"
      fixed
      placeholder
    />
    <div class="mainDiv GeLogo">
      <van-row  style="
        font-size: 12px;
        display: flex;
        align-items: center;
        font-family: PingFangSC-Regular;margin-top:10px"
      >
        <van-col span="">
          <div class="gexianbo" 
            style="width: 76px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 76px;">
            <van-image
              width="48"
              height="48"
              :src="tableData.taskInfo.logo"
            />
          </div>
        </van-col>

        <van-col span="" style="flex: 1; ">
            <span>
              <span  class="spandiv" v-if="tableData.taskInfo.name">{{tableData.taskInfo.name}}</span>
              <span class="spandiv2">剩余{{Math.round((tableData.taskInfo.end-tableData.taskInfo.start)/86400)}}天</span>
            </span>
        </van-col>

        <van-col span="" style="margin-right: 12px;">
          <span>
            <span style="font-size: 18px;
              font-family: PingFangSC-Regular;
              color: rgba(255, 82, 0, 1);
              overflow: hidden;
              text-overflow: ellipsis;"
            >
              +{{ parseInt(tableData.taskInfo.award) }}
            </span>
            <span style=" font-size: 12px;
            font-family: PingFangSC-Regular;
            color: rgba(255, 82, 0, 1);
            text-overflow: ellipsis;">{{tableData.taskInfo.unit}}</span>
            <span style="
            height: 17px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(153, 153, 153, 1);
            font-size: 12px;
            font-family: PingFangSC-Regular;
            white-space: nowrap;
            text-align: right;
            margin-top: 4px;">总奖励金额</span>   
          </span>
        </van-col>
      </van-row>
    </div>
    <!--完成注册--->
    <div class="mainDiv" style="padding: 20px 12px; box-sizing: border-box;">
      <div style="display: flex; justify-content: space-between; border-box; align-items: center;">
        <span style="color: #333; font-weight: 600;">游戏ID:{{ tableData.taskInfo.id }}</span>
        <van-button 
          size="mini" 
          round
          @click="DownLoad(tableData.taskInfo.link)"
          color="linear-gradient(to right, #fe5e33, #ee0d24)"
        >
          <div style="display: flex; align-items: center; font-size: 12px; padding: 0 9px;">
            <span>下载游戏</span>
          </div>
        </van-button>
      </div>
      <div style="color: #FF5200; font-size: 12px; margin: 19px 0 6px;">请下载游戏并注册账号，并返回本页点击“完成注册”</div>
      <div style="color: #999999; font-size: 12px;">[游戏ID]显示后，即可开始试玩！</div>
    </div>

    <div class="mainDiv get-detail">
      <p style="font-weight: bold;">游戏金币领取详情</p>
      <div class="get-detail-item">
        <div 
          v-for="(item, index) in tableData.taskInfo.demo_guide"
          :key="index"
        >{{ item }}</div>
      </div>
    </div>
    <div>
      <van-goods-action>
        <van-goods-action-button type="danger" text="领取奖励"  @click="GetAward" />
      </van-goods-action>
    </div>
  </div>
</template>

<script>
import { getObjDetail,getAward } from '@/api/Game'
export default {
  name: "makerGameDetail",
  data() {
    return {
      id: 2005,
      phone:1,
      device:'IDFA',
      tableData: {}
    };
  },
  components: {
  
  },
  created() {
    /**
   * 媒体方用户设备类型。Android - 1；iOS - 2
   */
      /**
   * 媒体方用户设备。iOS - IDFA；Android - IMEI（卡槽1）
   * (安卓10以上系统使用oaid)oaid的获取方式可参考：http://www.msa-alliance.cn/col.jsp?id=120
   */
    this.$store.commit('setSession', this.$route.query.session)
    if (this.$route.query.Id) {
      this.id = this.$route.query.Id;
      this.device = this.$route.query.device;
    }
    this.getPhoneType()
  },
  methods: {
    getPhoneType () {
      let u = navigator.userAgent
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if(isIOS)
      {
        this.phone=2
        this.getDetail()
      }
      if(isAndroid)
      {
        this.phone=1
        this.getDetail()
      }
    },

    getDetail () {
      let ispeed = Math.floor(-this.scrollTop / 5);
      document.documentElement.scrollTop = document.body.scrollTop =
      this.scrollTop + ispeed;
      //1,2 
      let param={
        id: this.$route.query.id,
        phone: this.phone,
        device: this.$route.query.idfa ? this.$route.query.idfa : this.$route.query.deviceId
      }
      getObjDetail(param).then((res)=> {
        if (res.data.code===0) {
          this.tableData=res.data.data
        }
      })
    },

    DownLoad (link) {
      try{
        window.webkit.messageHandlers.openBrowser.postMessage(link)
      }catch{ 
        window.android.openBrowser(link)
      }
    },

    GetAward () {
      let param={
        id: this.$route.query.id,
        device: this.$route.query.idfa ? this.$route.query.idfa : this.$route.query.deviceId,
        phone: this.phone
      }
      getAward(param).then((res) => {
        if (res.data.code===0) {
          this.$toast(res.data.data.msg)
        }else{
          this.$toast(res.data.data.msg)
        }
      })
    },

    goBack () {
      let u = navigator.userAgent
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if(isIOS) {
        window.webkit.messageHandlers.close.postMessage('up');   
      }
      if (isAndroid) {
        window.android.close(); 
      } 
    },
  }
}
</script>

<style lang="less" scoped>
.mainDiv{
    margin-top: 10px;
    margin-left: 2%;
    margin-right: 2%;
    border-radius: 5px;
    background-color: #ffffff;
    align-self: center;
    justify-content: center;
    width: 96%;
}
.gexianbo {
  /deep/ .van-image__img {
    border-radius: 5px;
    box-shadow: 0 0 8px 0 rgba( 0, 0, 0, .3);
  }
}
.spandiv {
  width: 64px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  font-family: PingFangSC-Semibold;
  white-space: nowrap;
  text-align: left;
}
.spandiv2 {
  width: 43px;
  height: 17px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 82, 0, 1);
  font-size: 12px;
  font-family: PingFangSC-Semibold;
  white-space: nowrap;
  text-align: left;
  align-self: flex-start;
  margin-top: 7px;
}

.bd-div {
  height: 17px; 
  width: 66px;;
  background-color: #fd8d30; 
  color: #fff; 
  font-size: 12px; 
  padding: 0;
  border-radius: 11.5px 11.5px 11.5px 11.5px;
}
.txt {
  width: 277px;
  height: 17px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 82, 0, 1);
  font-size: 12px;
  letter-spacing: -0.02500000037252903px;
  font-family: PingFangSC-Regular;
  white-space: nowrap;
  text-align: left;
  align-self: flex-start;
  margin-top: 10px;
}
.txt2 {
  width: 277px;
  height: 17px;
  display: block;
  overflow-wrap: break-word;
   color: rgba(153, 153, 153, 1);
  font-size: 12px;
  letter-spacing: -0.02500000037252903px;
  font-family: PingFangSC-Regular;
  white-space: nowrap;
  text-align: left;
  align-self: flex-start;
  margin-top: 5px;
}
.van-card__thumb {
    position: relative;
    -webkit-box-flex: 0;
    -webkit-flex: none;
    flex: none;
    width: 50px;
    height: 50px;
    margin-right: 8px;
}
/*.van-card {
    background-color: transparent;
}*/

/deep/ .van-nav-bar__title,
.spandiv,
.spandiv2 {
  font-weight: 600;
}
.get-detail {
  padding: 10px;
  box-sizing: border-box;
  &-item {
    height: 150px;
    overflow: auto;
  }
  &-item::-webkit-scrollbar {
    display: none;
  }
}
</style>